import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import Section from '../components/Common/Section'

const AboutUs = props => (
  <Layout>
    <SEO
      title="AS News | ¿Quiénes somos?"
      description="AS Consulting Group evoluciona. Gracias a esta premisa, nace AS News, un nuevo portal que está dirigido para toda aquella persona que desea emprender un negocio o ya tiene uno en marcha."
      url={props.location.href}
    />
    <div
      style={{
        backgroundImage: `url(${require('../images/background_asnews_us.png')})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Section>
        <div
          style={{
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            maxWidth: '700px',
            margin: '0 auto',
            backgroundColor: 'white',
            padding: '2rem 1rem',
            boxShadow: '80px 0 90px 0 #ffffff, -80px 0 90px 0 #ffffff',
          }}
        >
          <div style={{ textAlign: 'justify', fontSize: '1.2rem' }}>
            <h1
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '2.5rem',
              }}
            >
              <img
                alt="AS News logo"
                src={require('../images/icon_as.png')}
                height={65}
              />
              News
            </h1>
            <p>
              Estar a la vanguardia nunca antes ha sido una necesidad como
              ahora, transitamos diversos periodos generacionales con éxito, sin
              miedo a la diversidad y sabedores de que el cambio es lo único
              constante, por esta razón AS Consulting Group evoluciona. Gracias
              a esta premisa, nace AS News, un nuevo portal que está dirigido
              para toda aquella persona que desea emprender un negocio o ya
              tiene uno en marcha.
            </p>
            <p>
              Este nuevo medio representa el esfuerzo conjunto de profesionales
              en diversas áreas empresariales, pero con una meta en común:
              transmitir las experiencias que a lo largo de 28 años de práctica
              profesional, nos han permitido estar vigentes y consolidarnos, ese
              es el reto.
            </p>
            <p>
              Iniciamos hoy con la motivación, de que si sólo una idea de este
              esfuerzo, es puesta en marcha con beneficios y pasión, nuestro
              objetivo será cumplido.
            </p>
            <p>
              Emprender es una condición de todo ser humano, la única limitación
              es nuestro miedo o la inactividad. Estar instalado en una zona de
              confort, transforma la rutina en una vida. Si hoy te decides a
              emprender, te ayudaremos a llevarlo a cabo.
            </p>
            <p
              style={{
                textAlign: 'left',
                fontWeight: 'bold',
                fontSize: '1.2rem',
              }}
            >
              SOMOS LO QUE PENSAMOS. A TODA ACCIÓN HAY UNA REACCIÓN.
            </p>
          </div>
        </div>
      </Section>
    </div>
  </Layout>
)

export default AboutUs
